import { default as bulletinqZf4MM99VQMeta } from "/vercel/path0/pages/bulletin.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as leaves2nDZeJEK0kMeta } from "/vercel/path0/pages/leaves.vue?macro=true";
import { default as mailchimpMg1Z7FEkunMeta } from "/vercel/path0/pages/mailchimp.vue?macro=true";
import { default as maintenanceLjk4bqrWMWMeta } from "/vercel/path0/pages/maintenance.vue?macro=true";
import { default as missivezTpbAitFl7Meta } from "/vercel/path0/pages/missive.vue?macro=true";
import { default as searchBIFC2dE21WMeta } from "/vercel/path0/pages/search.vue?macro=true";
import { default as timesheetsPLxSvFbLH8Meta } from "/vercel/path0/pages/timesheets.vue?macro=true";
import { default as translatorrhZbooBanqMeta } from "/vercel/path0/pages/translator.vue?macro=true";
export default [
  {
    name: bulletinqZf4MM99VQMeta?.name ?? "bulletin",
    path: bulletinqZf4MM99VQMeta?.path ?? "/bulletin",
    meta: bulletinqZf4MM99VQMeta || {},
    alias: bulletinqZf4MM99VQMeta?.alias || [],
    redirect: bulletinqZf4MM99VQMeta?.redirect,
    component: () => import("/vercel/path0/pages/bulletin.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: leaves2nDZeJEK0kMeta?.name ?? "leaves",
    path: leaves2nDZeJEK0kMeta?.path ?? "/leaves",
    meta: leaves2nDZeJEK0kMeta || {},
    alias: leaves2nDZeJEK0kMeta?.alias || [],
    redirect: leaves2nDZeJEK0kMeta?.redirect,
    component: () => import("/vercel/path0/pages/leaves.vue").then(m => m.default || m)
  },
  {
    name: mailchimpMg1Z7FEkunMeta?.name ?? "mailchimp",
    path: mailchimpMg1Z7FEkunMeta?.path ?? "/mailchimp",
    meta: mailchimpMg1Z7FEkunMeta || {},
    alias: mailchimpMg1Z7FEkunMeta?.alias || [],
    redirect: mailchimpMg1Z7FEkunMeta?.redirect,
    component: () => import("/vercel/path0/pages/mailchimp.vue").then(m => m.default || m)
  },
  {
    name: maintenanceLjk4bqrWMWMeta?.name ?? "maintenance",
    path: maintenanceLjk4bqrWMWMeta?.path ?? "/maintenance",
    meta: maintenanceLjk4bqrWMWMeta || {},
    alias: maintenanceLjk4bqrWMWMeta?.alias || [],
    redirect: maintenanceLjk4bqrWMWMeta?.redirect,
    component: () => import("/vercel/path0/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: missivezTpbAitFl7Meta?.name ?? "missive",
    path: missivezTpbAitFl7Meta?.path ?? "/missive",
    meta: missivezTpbAitFl7Meta || {},
    alias: missivezTpbAitFl7Meta?.alias || [],
    redirect: missivezTpbAitFl7Meta?.redirect,
    component: () => import("/vercel/path0/pages/missive.vue").then(m => m.default || m)
  },
  {
    name: searchBIFC2dE21WMeta?.name ?? "search",
    path: searchBIFC2dE21WMeta?.path ?? "/search",
    meta: searchBIFC2dE21WMeta || {},
    alias: searchBIFC2dE21WMeta?.alias || [],
    redirect: searchBIFC2dE21WMeta?.redirect,
    component: () => import("/vercel/path0/pages/search.vue").then(m => m.default || m)
  },
  {
    name: timesheetsPLxSvFbLH8Meta?.name ?? "timesheets",
    path: timesheetsPLxSvFbLH8Meta?.path ?? "/timesheets",
    meta: timesheetsPLxSvFbLH8Meta || {},
    alias: timesheetsPLxSvFbLH8Meta?.alias || [],
    redirect: timesheetsPLxSvFbLH8Meta?.redirect,
    component: () => import("/vercel/path0/pages/timesheets.vue").then(m => m.default || m)
  },
  {
    name: translatorrhZbooBanqMeta?.name ?? "translator",
    path: translatorrhZbooBanqMeta?.path ?? "/translator",
    meta: translatorrhZbooBanqMeta || {},
    alias: translatorrhZbooBanqMeta?.alias || [],
    redirect: translatorrhZbooBanqMeta?.redirect,
    component: () => import("/vercel/path0/pages/translator.vue").then(m => m.default || m)
  }
]