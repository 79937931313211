export default defineNuxtRouteMiddleware((to) => {
  const config = useRuntimeConfig();
  const maintenanceMode = config.public.enableMaintenanceMode;
  const allowedPaths = ["/maintenance"];

  if (maintenanceMode && !allowedPaths.includes(to.path)) {
    return navigateTo("/maintenance");
  }

  if (!maintenanceMode && to.path === "/maintenance") {
    return navigateTo("/");
  }
});
