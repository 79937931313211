<script setup>
</script>

<template>
  <div>
    <NuxtLoadingIndicator color="#3b82f6" />
    <UNotifications />

    <div>
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </div>
  </div>
</template>
